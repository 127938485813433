import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2";
import { toast } from "react-toastify";
import Progress from "../components/Progress";
import Title from "../components/Title";
import authFetch from "../axios";
import { STEP, SUCCESS_STATUS_DIGIT } from "../utils";
import FixedHeader from "../components/FixedHeader";
import fg from "../assets/npc/fg.png";
import BackgroundImage from "../components/BackgroundImage";
import watermark from "../assets/npc/npc-watermark.png";
import Select from "react-select";
import AsyncSelect from "react-select/async";


const Banking = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [bank, setBanks] = useState([]);
  const [fetchBanks, setFetchBanks] = useState([]);
  const [numb, setNumb] = useState("");
  const [selectedBank, setSelectedBank] = useState({ code: "", name: "" });
  const [accName, setAccName] = useState("");
  const [loading, setLoading] = useState(false);
  const [preloading, setPreLoading] = useState(false);
  const [acctyp, setAccType] = useState("");
  const sun = sessionStorage.getItem("lname")?.toLowerCase();
  const fir = sessionStorage.getItem("fname")?.toLowerCase();
  const bearerToken = sessionStorage.getItem("userToken");

  //account types
  const accountTypes = [
    { value: "10", label: "Savings Account" },
    { value: "20", label: "Current Account" },
  ];

  const filterBanks = (inputValue) => {
    return fetchBanks.filter((bank) =>
      bank.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterBanks(inputValue));
    });
  };

  useEffect(() => {
    const getBankDetails = async () => {
      try {
        const config = {
          method: "get",
          url: "/resources/banks",
        };
        const { data } = await authFetch(config);
        setFetchBanks(data);
      } catch (error) {
        console.error("Error fetching bank details:", error);
      }
    };

    getBankDetails();
  }, []);

  const handleBankChange = (selectedOption) => {
    const { code, name } = selectedOption;
    setSelectedBank({ code, name });
    console.log("Selected bank code:", code);
    console.log("Selected bank name:", name);
  };

  const handleBankNumberChange = (e) => {
    setNumb(e.target.value);
  };

  const handleSubmit = async (e, accountName) => {
    e.preventDefault();
    try {
      const data3 = {
        account_number: numb,
        bank_name: selectedBank.name,
        account_type: acctyp,
        account_name: accountName,
        bank_code: selectedBank.code,
      };

      const config = {
        method: "post",
        url: "/applicant/update-bank-data",
        data: data3,
      };

      const { data } = await authFetch(config);

      if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
        toast.success(
          `${data.palliative.message}, further info will be sent to your email`,
          {
            position: "top-center",
          }
        );
        navigate("/prev");
      } else {
        toast.error(data.palliative.message, {
          position: "top-center",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Please retry", {
        position: "top-left",
      });
    }
  };

  const handleAppStart = async (e) => {
    e.preventDefault();
    if (!acctyp) {
      Swal.fire({
        title: " Error",
        text: "Please select account type!",
        icon: "error",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
    } else if (numb.length < 10) {
      Swal.fire({
        title: " Error",
        text: "Please enter a valid account number!",
        icon: "error",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
    } else if (selectedBank.code === "") {
      Swal.fire({
        title: " Error",
        text: "Please select a bank",
        icon: "error",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
    } else {
      try {
        Swal.fire({
          title: "Validating...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton());
          },
        });

        const data = {
          accountNumber: numb,
          bankCode: selectedBank.code,
        };

        var config1 = {
          method: "post",
          url: "/resources/validate-account",

          data: data,
        };
        const res = await authFetch(config1);

        if (res.data.status === false && numb) {
          setAccName("");
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: res.data.message,
            icon: "warning",

            confirmButtonColor: "#0b6916",
            confirmButtonText: "Ok",
          });
        } else if (res.data.status === true) {
          Swal.close();
          setAccName(res.data.data.account_name);
          const accountName = res.data.data.account_name;

          //check if account name matches
          if (
            !accountName.toLowerCase().includes(sun) &&
            !accountName.toLowerCase().includes(fir)
          ) {
            Swal.fire({
              title: " Error",
              text: "Please use your account!",
              icon: "error",

              confirmButtonColor: "#0b6916",
              confirmButtonText: "Ok",
            });
          } else {
            handleSubmit(e, accountName);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      setPreLoading(true);
      try {
        var config = {
          method: "get",
          url: "/auth/retrive-data",
        };
        const { data } = await authFetch(config);
        if (data.palliative.step < STEP.RESIDENCE) {
          navigate(-1);
        }
        setPreLoading(false);
      } catch (error) {
        console.log(error);
        setPreLoading(false);
      }
    };
    getDetails();
  }, [navigate, bearerToken]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const res = await authFetch.get("/resources/banks");
        setBanks(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBanks();
  }, []);

  return (
    <>
      <Wrapper className="nin-container">
        <FixedHeader logo={fg} title="New Application" />
        <>
          {preloading ? (
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mx-auto"
            />
          ) : (
            <div className="container">
              <div className="pt-5 pb-5"></div>
              <div className="">
                <Progress active={3} />
                <div className="pt-3"></div>
                <Title title="Bank Details" />
                <BackgroundImage src={watermark} alt="Background Image" />
              </div>
              <div className="row">
                <div className="col-10 mx-auto">
                  <Form className="">
                    <Row>
                      <Col
                        md={12}
                        lg={12}
                        className="p-2"
                        style={{
                          background: "#F5F5F5",
                          margin: "9px 0px",
                          padding: "10px",
                        }}
                      >
                        <div>
                          {" "}
                          <ul>
                            <li
                              style={{
                                color: "red",
                                width: "100%",
                                margin: "opx 8px",
                                fontSize: "18px",
                              }}
                              className="text-center"
                            >
                              NOTE
                            </li>
                            <li>
                              {" "}
                              - Registration Agent's account name must be the
                              same as the name used during application.
                            </li>
                            <li>
                              - Applicant must be banking with a commercial
                              bank.
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEText">
                          <Form.Label className="text-left">
                            <strong> Account Number</strong>
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Enter account number"
                            name="numb"
                            className="form_input shadow-none"
                            value={numb}
                            onChange={handleBankNumberChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={6}>
                        <Form.Label className="text-left">
                          <strong>Select Bank</strong>
                        </Form.Label>

                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadOptions}
                          defaultOptions
                          onChange={handleBankChange}
                          value={selectedBank}
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.code}
                          placeholder="Select Bank"
                        />
                      </Col>

                      <Col md={6} lg={6}>
                        <Form.Label className="text-left">
                          <strong> Select Account type</strong>
                        </Form.Label>
                        <Select
                          options={accountTypes}
                          onChange={(selectedOption) => {
                            setAccType(selectedOption.value);
                          }}
                        />
                      </Col>

                      {accName && !loading ? (
                        <Col md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEText"
                          >
                            <Form.Label className="text-left">
                              <strong> Account Name </strong>
                            </Form.Label>
                            <Form.Control
                              readOnly
                              required
                              type="text"
                              name="accname"
                              className="form_input shadow-none"
                              value={accName}
                            />
                          </Form.Group>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                    {loading && (
                      <div className="row py-3">
                        <div className="col-6  col-md-6 mx-auto">
                          <Spinner animation="border" variant="success" />
                        </div>
                      </div>
                    )}
                  </Form>

                  <button
                    style={{
                      backgroundColor: "#0e9738",
                      padding: "10px 20px",
                      fontSize: "13px",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                    type="submit"
                    className="my-btn my-2 px-5 mt-5"
                    disabled={loading}
                    onClick={handleAppStart}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  min-height: calc(100vh - 102.4px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

export default Banking;
