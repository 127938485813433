import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Error from "./pages/Error";
import Landing from "./pages/Landing";
import Nin from "./pages/Nin";
import Instructions from "./pages/Instructions";
import BioData from "./pages/BioData";

import Banking from "./pages/Banking";

import Disclaimer from "./pages/Disclaimer";
import Residence from "./pages/Residence";
import Contact from "./pages/Contact";
import Prev from "./pages/Preview";

import Test from "./pages/Test";
import { WebcamCapture } from "./pages/Face";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./components/ProtectedRoute";
const App = () => {
  return (
    <Router>
      <ToastContainer />

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="nin" element={<Nin />} />
        <Route path="instructions" element={<Instructions />} />
        <Route
          path="test"
          element={
            <ProtectedRoute>
              <Test />
            </ProtectedRoute>
          }
        />

        <Route
          path="contact"
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          }
        />
        <Route
          path="bio-data"
          element={
            <ProtectedRoute>
              <BioData />
            </ProtectedRoute>
          }
        />

        <Route
          path="face-capture"
          element={
            <ProtectedRoute>
              <WebcamCapture />
            </ProtectedRoute>
          }
        />
        <Route
          path="residence"
          element={
            <ProtectedRoute>
              <Residence />
            </ProtectedRoute>
          }
        />

        <Route
          path="banking-info"
          element={
            <ProtectedRoute>
              <Banking />
            </ProtectedRoute>
          }
        />

        <Route
          path="prev"
          element={
            <ProtectedRoute>
              <Prev />
            </ProtectedRoute>
          }
        />

        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
};

export default App;
