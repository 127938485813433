import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { handleChange } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2";
import authFetch from "../axios";
import { EDIT_TYPE, isValidNigerianPhoneNumber, STEP, SUCCESS_STATUS_DIGIT } from "../utils";
import FixedHeader from "../components/FixedHeader";
import fg from "../assets/npc/fg.png";
import watermark from "../assets/npc/npc-watermark.png";
import BackgroundImage from "../components/BackgroundImage";

const Contact = () => {
  const [ot, showOt] = useState(false);
  const [code, setCode] = useState("");
  const [ver1, setVer1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const handleOtpInp = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setCode(e.target.value);
  };
  const navigate = useNavigate();
  const { editCon, phone, email } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const verPhone = async () => {
    if (!email) {
      Swal.fire({
        title: "Error",
        text: "Email can not be empty",
        icon: "error",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
      return;
    } else {
      setLoading(true);
      try {
        const data1 = { email };
        console.log(email);

        var config = {
          method: "post",
          url: "/applicant/verify-email",
          data: data1,
        };

        const { data } = await authFetch(config);
        if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
          setLoading(false);
          Swal.fire({
            title: " Success",
            text: "Check your email",
            icon: "success",

            confirmButtonColor: "#0b6916",
            confirmButtonText: "Ok",
          });

          showOt(true);
        } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: `${data.palliative.msg} || please retry`,
            icon: "error",
            confirmButtonColor: "#0b6916",
            confirmButtonText: "Ok",
          });
          showOt(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Please try again",
          icon: "error",

          confirmButtonColor: "#0b6916",
          confirmButtonText: "Ok",
        });
      }
    }
  };
  const verOtp = async () => {
    if (!code) {
      Swal.fire({
        title: "Error",
        text: "OTP is required",
        icon: "error",
        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
      return;
    }
    setLoading(true);
    try {
      const data3 = { otp: code };
      var config = {
        method: "post",

        url: "/applicant/verify-otp",

        data: data3,
      };
      const { data } = await authFetch(config);
      console.log(data);
      if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
        setLoading(false);
        Swal.fire({
          title: "Success",
          text: "OTP verified",
          icon: "success",
          confirmButtonColor: "#0b6916",
          confirmButtonText: "Ok",
        });

        setVer1(true);
        setCode("");
        showOt(false);
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "OTP is not verified retry",
          icon: "error",
          confirmButtonColor: "#0b6916",
          confirmButtonText: "Ok",
        });

        setVer1(false);
        setCode("");
        showOt(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "please try again",
        icon: "error",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ver1 === false) {
      Swal.fire({
        title: " Verification Unsuccessful!",
        text: "Verify email.",
        icon: "warning",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
      return;
    } else if (await isValidNigerianPhoneNumber(phone) === false) {
      console.log('Invalid phone number.');
      Swal.fire({
        text: "Please enter a valid phone number.",
        icon: "warning",

        confirmButtonColor: "#0b6916",
        confirmButtonText: "Ok",
      });
      return;
    } else {
      if (editCon) {
        setLoadingSubmit(true);
        try {
          const data5 = {
            email,
            phone,
            edit: EDIT_TYPE.EDIT,
          };

          var config = {
            method: "post",
            url: "/applicant/update-contact",

            data: data5,
          };
          const { data } = await authFetch(config);
          console.log(data.palliative);
          if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILED) {
            Swal.fire({
              title: " Validation Failed!",
              text: data.palliative.message,
              icon: "error",

              confirmButtonColor: "#0b6916",
              confirmButtonText: "Retry",
            });
          } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
            Swal.fire({
              title: " Validation Successful!",
              text: data.palliative.message,
              icon: "success",

              confirmButtonColor: "#0b6916",
              confirmButtonText: "Proceed",
            });
            setLoadingSubmit(false);
            dispatch(handleChange({ name: "editCon", value: false }));
            navigate("/prev");
          }
        } catch (error) {
          console.log(error);
          setLoadingSubmit(false);
          toast.error("please retry", {
            position: "top-left",
          });
        }
      } else {
        setLoadingSubmit(true);
        try {
          const data6 = {
            email,
            phone,
            edit: EDIT_TYPE.NEW,
          };

          var config1 = {
            method: "post",
            url: "/applicant/update-contact",

            data: data6,
          };
          const { data } = await authFetch(config1);
          console.log(data.palliative);
          if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILED) {
            Swal.fire({
              title: "Validation Failed!",
              text: data.palliative.message,
              icon: "error",

              confirmButtonColor: "#0b6916",
              confirmButtonText: "Retry",
            });
          } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
            setLoadingSubmit(false);
            Swal.fire({
              title: " Validation Successful!",
              text: `Please note that the next step of this application is for the applicant's test assessment`,
              icon: "success",
              confirmButtonColor: "#0b6916",
              confirmButtonText: "Start Test",
            }).then((result) => {
              if (result.value) {
                navigate("/test");
              }
            });
          }
        } catch (error) {
          console.log(error);
          setLoadingSubmit(false);
          toast.error("please retry", {
            position: "top-left",
          });
        }
      }
    }
  };

  const handleInp = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    dispatch(handleChange({ name, value }));
  };

  const kpx = (e) => {
    if (e.target.value.length === SUCCESS_STATUS_DIGIT.FAILURE) {
      showOt(false);
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      try {
        var config = {
          method: "get",
          url: "/auth/retrive-data",
        };
        const { data } = await authFetch(config);
        if (data.palliative.step >= STEP.CONTACT) {
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDetails();
  }, []);

  return (
    <>
      <Wrapper>
        <FixedHeader logo={fg} title="Contact Information" />
        <div className="container">
          <div className="pt-5 pb-3"></div>
          <Row>
            <Col
              md={12}
              lg={12}
              className="p-2"
              style={{
                background: "#F5F5F5",
                margin: "9px 0px",
                padding: "10px",
              }}
            >
              <div>
                {" "}
                <ul className="text-center">
                  <li
                    style={{
                      color: "red",
                      width: "100%",
                      margin: "opx 8px",
                      fontSize: "18px",
                    }}
                    className="text-center"
                  >
                    NOTE
                  </li>
                  <li>
                    {" "}
                    - Ensure to correctly enter your phone number and email
                    address.
                  </li>
                  <li> - Phone number should start with 0 and must be eleven digits.</li>
                  <li> - OTP expires after 5 minutes.</li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="">
            <BackgroundImage src={watermark} alt="Background Image" />
            <Row className="mt-2">
              <Col md={6} lg={6}>
                <Form.Label className="text-left">Email address</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    required
                    placeholder="Enter email"
                    name="email"
                    aria-describedby="basic-addon2"
                    className="form_input shadow-none"
                    type="email"
                    value={email}
                    onChange={handleInp}
                    onKeyUp={kpx}
                  />
                  <Button
                    style={{
                      backgroundColor: "#0e9738",
                      padding: "10px 20px",
                      fontSize: "13px",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                    id="button-addon2"
                    className="my-btn"
                    disabled={ver1}
                    onClick={verPhone}
                  >
                    {ver1 ? "verified" : "validate email"}
                  </Button>
                </InputGroup>

                {ot && (
                  <>
                    <p
                      className=""
                      style={{
                        color: "red",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={verPhone}
                    >
                      resend otp
                    </p>
                    <Form.Label className="text-left">Enter OTP</Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl
                        required
                        type="number"
                        placeholder="Enter otp"
                        name="otp"
                        aria-describedby="basic-addon2"
                        className="form_input shadow-none"
                        value={code}
                        onChange={handleOtpInp}
                      />
                      <Button
                        style={{
                          backgroundColor: "#0e9738",
                          padding: "10px 20px",
                          fontSize: "13px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                        id="button-addon2"
                        className="my-btn"
                        onClick={verOtp}
                      >
                        validate
                      </Button>
                    </InputGroup>
                  </>
                )}
              </Col>

              <Col md={6} lg={6}>
                <Form.Label className="text-left">Phone Number</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    required
                    type="number"
                    placeholder="Enter phone number"
                    name="phone"
                    className="form_input shadow-none"
                    value={phone}
                    onChange={handleInp}
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 11))
                    }
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3} lg={3} className="pt-2 d-flex justify-content-end">
                <div style={{ display: "flex", gap: "1rem" }}>
                  <button
                    type="button"
                    onClick={() => {
                      navigate(-1);
                    }}
                    disabled={loadingSubmit}
                    className="back-btn btn-block mt-4"
                  >
                    {"BACK"}
                  </button>
                  <button
                    style={{
                      backgroundColor: "#0e9738",
                      padding: "10px 20px",
                      fontSize: "12px",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loadingSubmit}
                    className="my-btn btn-block mt-4 ml-auto"
                  >
                    {editCon ? "UPDATE" : "SAVE AND START TEST"}
                  </button>
                </div>
              </Col>
            </Row>

            {loading && (
              <div className="row py-3">
                <div className="col-6  col-md-6 mx-auto">
                  <Spinner animation="border" variant="success" />
                </div>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  padding: 1.6rem 0;
  min-height: calc(100vh - 107px);
  text-transform: uppercase;
`;

export default Contact;
