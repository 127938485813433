import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useNavigate } from "react-router-dom";
import { saveNin } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import authFetch from "../axios";
import watermark from "../assets/npc/npc-watermark.png";
import fg from "../assets/npc/fg.png";
import FixedHeader from "../components/FixedHeader";
import BackgroundImage from "../components/BackgroundImage";

const Nin = () => {
  const { usertype } = useSelector((state) => state.user);
  const [ninNum, setNinNum] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    if (buttonName === "Validate") {
      handleAppStart();
    } else {
      navigate("/instructions");
    }
  };

  const handleAppStart = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    if (ninNum.length > 11 || ninNum.length < 11) {
      Swal.fire({
        title: "NIN number must be 11 digits",
        text: "",
        icon: "error",
        confirmButtonColor: "#0b6916",
        confirmButtonText: "OKAY",
      });
      setLoading(false);
      return;
    }
    try {
      var jsonData = {
        nin: ninNum,
      };
      var config = {
        method: "post",
        url: `/nin/verify`,
        data: jsonData,
      };
      const { data } = await authFetch(config);
      console.log(data);
      if (data.palliative.statusCode !== 200) {
        setLoading(false);
        Swal.fire({
          title: "NIN verification failed",
          text: "Please try again later",
          icon: "error",
          confirmButtonColor: "#0b6916",
          confirmButtonText: "OKAY",
        });
        return;
      } else if (
        data.palliative.statusCode === 200 &&
        data.palliative.firstname === null
      ) {
        setLoading(false);
        Swal.fire({
          title: `${data.palliative.message || "please retry"}`,
          text: "retry later",
          icon: "error",
          confirmButtonColor: "#0b6916",
          confirmButtonText: "OKAY",
        });
        return;
      } else if (
        data.palliative.statusCode === 200 &&
        data.palliative.firstname === ""
      ) {
        setLoading(false);
        Swal.fire({
          title: `${data.palliative.message || "please retry"}`,
          text: "retry",
          icon: "error",

          confirmButtonColor: "#0b6916",
          confirmButtonText: "Okay",
        });
        return;
      } else if (data.palliative.is_exist === true) {
        setLoading(false);
        Swal.fire({
          title: "NIN already used",
          icon: "error",

          confirmButtonColor: "#0b6916",
          confirmButtonText: "Okay",
        });
        return;
      } else {
        setLoading(false);
        Swal.fire({
          title: "NIN verification done",
          text: `${data.palliative.firstname} ${data.palliative.lastname}`,
          icon: "success",
          imageWidth: "100px",
          imageHeight: "100px",
          confirmButtonColor: "#0b6916",
          confirmButtonText: "Liveness match",
        }).then((result) => {
          if (result.value) {
            sessionStorage.setItem("fname", data.palliative.firstname);
            sessionStorage.setItem("lname", data.palliative.lastname);
            sessionStorage.setItem("dob", data.palliative.dob);
            sessionStorage.setItem("gender", data.palliative.gender);
            sessionStorage.setItem("phone", data.palliative.phone);
            sessionStorage.setItem("photo", data.palliative.photo);
            sessionStorage.setItem("nin", ninNum);
            setLoading(false);
            const dqm = sessionStorage.getItem("access_code") || "";

            saveNin(
              ninNum,
              usertype,
              dqm,
              data.palliative.firstname,
              data.palliative.lastname,
              "",
              data.palliative.dob,
              data.palliative.gender,
              dispatch
            ).then((res) => {
              if (res === true) {
                window.location.href = "/face-capture";
              }
            });
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(
        `${error.response.data.palliative.message || "please retry"}`,
        {
          position: "top-center",
        }
      );
    }
  };

  useEffect(() => {
    const access = sessionStorage.getItem("checkFirst");
    if (!access) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Wrapper className="nin-container">
      <FixedHeader logo={fg} title="New Application Instructions" />
      <div className="container">
        <div className="row">
          <div className="col-10 mx-auto">

            <BackgroundImage src={watermark} alt="Background Image" />
           
            <Form className="" onSubmit={handleAppStart}>
              <h4>NIN Verification</h4>
              <p>
                Registration agents National Identification Number (NIN) can
                only be used once to access the assessment test.
              </p>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left">
                  National Identification Number(NIN)
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Your NIN"
                  className="form_input shadow-none"
                  name="nin_code"
                  onChange={(e) => setNinNum(e.target.value)}
                  maxLength={11}
                  minLength={11}
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 11))
                  }
                />
              </Form.Group>
              <ButtonContainer>
                <ButtonWhite onClick={() => handleButtonClick("Back")}>
                  Back
                </ButtonWhite>
                <ButtonGreen onClick={() => handleButtonClick("Validate")}>
                  Validate NIN
                </ButtonGreen>
              </ButtonContainer>
            </Form>
          </div>
        </div>
        {loading && (
          <div className="row py-3">
            <div className="col-6  col-md-6 mx-auto">
              <Spinner animation="border" variant="success" />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
`;

const ButtonWhite = styled(Button)`
  background-color: white;
  border: 1px solid #0e9738;
  color: #0e9738;
`;

const ButtonGreen = styled(Button)`
  background-color: #0e9738;
  border: none;
  color: white;
`;

export default Nin;
