import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleChange, handleStChange } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Title from "../components/Title";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import { Spinner } from "react-bootstrap";
import { components } from "react-select";
import Progress from "../components/Progress";
import Swal from "sweetalert2/dist/sweetalert2.js";
import authFetch from "../axios";
import { EDIT_TYPE, STEP, SUCCESS_STATUS_DIGIT } from "../utils";
import FixedHeader from "../components/FixedHeader";
import fg from "../assets/npc/fg.png";
import watermark from "../assets/npc/npc-watermark.png";
import BackgroundImage from "../components/BackgroundImage";

const BioData = () => {
  const navigate = useNavigate();
  const isValidDate = (current) => {
    const year = current.year();
    return year >= 1957 && year <= 2004;
  };

  const options = [
    { value: "English", label: "English" },
    { value: "Yoruba", label: "Yoruba" },
    { value: "Hausa", label: "Hausa" },
    { value: "Igbo", label: "Igbo" },
    { value: "Fulfude", label: "Fulfude" },
    { value: "Bassa", label: "Bassa" },
    { value: "Efik", label: "Efik" },
    { value: "Urhobo", label: "Urhobo" },
    { value: "Kanuri", label: "Kanuri" },
    { value: "Gbagi", label: "Gbagi" },
  ];

  const difficultyOptions = [
    { value: "no", label: "No" },
    { value: "sight(seeing)", label: "Sight(Seeing)" },
    {
      value: "physical(Walking Or Climbing Stairs)",
      label: "Physical(Walking Or Climbing Stairs)",
    },
    {
      value: "cognitive(Remembering Or Concentrating)",
      label: "Cognitive(Remembering Or Concentrating)",
    },
    {
      value: "self Care(Washing All Over Or Dressing)",
      label: "Self Care(Washing All Over Or Dressing)",
    },
    { value: "Speech(Speaking)", label: "Speech(Speaking)" },
  ];

  const computerOptions = [
    { value: "basic", label: "Basic" },
    { value: "intermediate", label: "Intermediate" },
    { value: "advanced", label: "Advanced" },
  ];

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const {
    userState,
    lga,
    surname,
    firstname,
    midname,
    gender,
    date,
    criminal,
    computer,
    language,
    challenge,
    kinname,
    kinphone,
    kinrel,
    accessCode,
    otherLang,
    editBio,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [lg, setLg] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // eslint-disable-next-line
  const [loadingSave, setLoadingSave] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [lgas, setLga] = useState([]);
  const [crimeDescription, setCrimeDescription] = useState("");

  const pattern = /^(09|08|07)/;

  const handleSubmit = async () => {
    sessionStorage.setItem("fname", firstname);
    sessionStorage.setItem("lname", surname);

    if (
      !accessCode ||
      !firstname ||
      !surname ||
      !date ||
      !gender ||
      !userState ||
      !lga ||
      !language ||
      !criminal ||
      !kinname ||
      !kinphone
    ) {
      toast.error("Fill all required input fields", {
        position: "top-center",
      });
      return;
    }

    if (!pattern.test(kinphone)) {
      toast.error("use a valid next of kin phone number", {
        position: "top-center",
      });
      return;
    }
    if (editBio) {
      setLoadingSubmit(true);
      try {
        const jsonData = {
          firstName: firstname,
          lastName: surname,
          middleName: midname,
          dob: date,
          gender: gender,
          state: userState,
          lga,
          physical_challenge: challenge,
          computer_skill: computer,
          language,
          other_language: otherLang,
          criminal_record: criminal,
          next_kin_name: kinname,
          next_kin_phone: kinphone,
          next_kin_relationship: kinrel,
          edit: EDIT_TYPE.EDIT,
        };

        var config1 = {
          method: "post",
          url: "/applicant/update-bio-data",
          data: jsonData,
        };
        const { data } = await authFetch(config1);
        if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
          toast.success("Application sent successfully", {
            position: "top-center",
          });
          setLoadingSubmit(false);
          dispatch(handleChange({ name: "editBio", value: false }));
          sessionStorage.setItem("editBio", false);
          navigate("/prev");
        } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
          toast.error(data.palliative.message, {
            position: "top-center",
          });
          setLoadingSubmit(false);
        }
      } catch {
        toast.error("please retry", {
          position: "top-left",
        });
        setLoadingSubmit(false);
      }
    } else {
      if (!date) {
        toast.error("Please fill in date", {
          position: "top-center",
        });
        return;
      }
      if (!language) {
        toast.error("Please fill in language", {
          position: "top-center",
        });
        return;
      } else {
        setLoadingSubmit(true);
        try {
          const jsonData2 = {
            firstName: firstname,
            lastName: surname,
            middleName: midname,
            dob: date,
            gender: gender,
            state: userState,
            lga,
            physical_challenge: challenge,
            computer_skill: computer,
            language,
            other_language: otherLang,
            criminal_record: criminal,
            next_kin_name: kinname,
            next_kin_phone: kinphone,
            next_kin_relationship: kinrel,
            edit: EDIT_TYPE.NEW, // mean edit, empty or zero mean new record
          };

          var config = {
            method: "post",
            url: "/applicant/update-bio-data",
            data: jsonData2,
          };
          const { data } = await authFetch(config);
          console.log(data.palliative);
          if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
            toast.success("Application sent successfully", {
              position: "top-center",
            });
            setLoadingSubmit(false);
            navigate("/residence");
          } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
            toast.error(data.palliative.message, {
              position: "top-center",
            });
            setLoadingSubmit(false);
          }
        } catch {
          toast.error("please retry", {
            position: "top-left",
          });
          setLoadingSubmit(false);
        }
      }
    }
  };

  // eslint-disable-next-line
  const handleSave = async (e) => {
    sessionStorage.setItem("fname", firstname);
    sessionStorage.setItem("lname", surname);
    if (
      !accessCode ||
      !firstname ||
      !surname ||
      !date ||
      !gender ||
      !userState ||
      !lga ||
      !language ||
      !criminal ||
      !kinname ||
      !kinphone
    ) {
      toast.error("fill all inputs before saving", {
        position: "top-center",
      });
      return;
    }
    if (!pattern.test(kinphone)) {
      toast.error("use a valid next of kin phone number", {
        position: "top-center",
      });
      return;
    }
    setLoadingSave(true);
    try {
      const jsonData3 = {
        firstName: firstname,
        lastName: surname,
        middleName: midname,
        dob: date,
        gender: gender,
        state: userState,
        lga,
        physical_challenge: challenge,
        computer_skill: computer,
        language,
        other_language: otherLang,
        criminal_record: criminal,
        next_kin_name: kinname,
        next_kin_phone: kinphone,
        next_kin_relationship: kinrel,
        edit: EDIT_TYPE.NEW,
      };
      var config = {
        method: "post",
        url: "/applicant/update-bio-data",
        data: jsonData3,
      };
      const { data } = await authFetch(config);
      console.log(data.palliative);
      if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
        toast.success("Application sent successfully", {
          position: "top-center",
        });
        setLoadingSave(false);
        sessionStorage.removeItem("dataMine");
        sessionStorage.clear();
        navigate("/");
      } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
        toast.error(data.palliative.message, {
          position: "top-center",
        });
        setLoadingSave(false);
      }
    } catch {
      toast.error("please retry", {
        position: "top-left",
      });
      setLoadingSave(false);
    }
  };

  const handleInp = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };

  const handlePhysicalChallenge = (selectedOption) => {
    const name = "challenge";
    const value = selectedOption.value;
    dispatch(handleChange({ name, value }));
  };

  const handleComputerProficiency = (selectedOption) => {
    const name = "computer";
    const value = selectedOption.value;
    dispatch(handleChange({ name, value }));
  };

  const handleCriminal = (e) => {
    const value = e.target.value;
    if (value === "yes") {
      setShowDesc(true);
      dispatch(handleChange({ name: "criminal", value: "yes" }));
    } else if (value === "no") {
      setShowDesc(false);
      dispatch(handleChange({ name: "criminal", value: "no" }));
    }
  };

  const handleCrimeDescription = (e) => {
    const value = e.target.value;
    if (value.length > 250) {
      return;
    }
    if (/^[A-Za-z\s]*$/.test(value) || value === "") {
      // Only allow alphabetic characters and spaces, or an empty string
      setCrimeDescription(value);
      sessionStorage.setItem("crimeDescription", value);
    }
  };

  const handleMultiiSelect = (selectedOption) => {
    console.log(selectedOption);
    const news = selectedOption.map((it, i) => {
      return it.value;
    });
    const name = "language";
    const newz = news.join(",");
    dispatch(handleChange({ name, value: newz }));
    console.log(news, newz);
  };

  const handleStateChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const tx = e.target.options[e.target.selectedIndex].text;
    dispatch(handleChange({ name, value }));
    dispatch(handleStChange({ name: "userStateDet", value: tx }));
    console.log(userState);

    var config = {
      method: "get",
      url: `resources/lga?stateId=${value}`,
    };
    const { data } = await authFetch(config);
    setLga(data.palliative);
  };

  const handleC = (newValue) => {
    console.log(newValue);
    const news = newValue.map((it, i) => {
      return it.value;
    });
    const name = "otherLang";
    const newz = news.join(",");
    dispatch(handleChange({ name, value: newz }));
  };

  const handleLgaChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const lgtx = e.target.options[e.target.selectedIndex].text;
    dispatch(handleChange({ name, value }));
    dispatch(handleStChange({ name: "lgaDet", value: lgtx }));

    var config = {
      method: "get",
      url: `resources/ward?lgaId=${value}`,
    };
    const { data } = await authFetch(config);
    console.log(data.palliative);
  };

  useEffect(() => {
    const getDetails = async () => {
      try {
        var config = {
          method: "get",
          url: "/auth/retrive-data",
        };
        const { data } = await authFetch(config);
        console.log(data.palliative[0]);
        if (
          data.palliative.step < STEP.TEST &&
          data.palliative.test_passed === false
        ) {
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDetails();
  }, [navigate]);

  useEffect(() => {
    const fetchState = async () => {
      try {
        const res = await authFetch.get("resources/states");

        setLg(res.data.palliative);
      } catch (error) {
        console.log(error);
      }
    };
    fetchState();
  }, []);

  useEffect(() => {
    // Retrieve the saved criminal case option
    const savedCrimeDescription = sessionStorage.getItem("crimeDescription");
    if (criminal) {
      if (criminal === "yes") {
        setShowDesc(true);
      }
    }
    if (savedCrimeDescription) {
      setCrimeDescription(savedCrimeDescription);
    }
  }, [criminal]);

  useEffect(() => {
    const fetchLgas = async () => {
      try {
        const res = await authFetch.get(`resources/lga?stateId=${userState}`);

        setLga(res.data.palliative);
      } catch (error) {
        console.log(error);
      }
    };
    if (userState != null) {
      fetchLgas();
    }
  }, [userState]);

  useEffect(() => {
    const typ = sessionStorage.getItem("user-type");
    if (!typ) {
      return;
    }
    Swal.fire({
      title: `Please save your Application ID`,
      icon: "success",
      text: `${accessCode} - Your ID can also be copied on the header`,
      confirmButtonColor: "#0b6916",
      confirmButtonText: "Okay",
    });
  }, [accessCode]);
  return (
    <Wrapper>
      <FixedHeader logo={fg} title="New Application" />
      <div className="container">
        <div className="pt-5 pb-5"></div>

        <div className="">
          <Progress active={1} />
          <div className="pt-3"></div>
          <Title title="Personal information" />
          <div className="pb-5"></div>
          <BackgroundImage src={watermark} alt="Background Image" />

          <Row>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">Surname</Form.Label>
                <Form.Control
                  readOnly={true}
                  required
                  type="text"
                  placeholder="Enter Surname"
                  className="form_input shadow-none"
                  name="surname"
                  value={surname}
                  onChange={handleInp}
                  style={{
                    background: "#f5f5f5",
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">
                  First Name
                </Form.Label>
                <Form.Control
                  readOnly={true}
                  required
                  type="text"
                  placeholder="Enter Firstname"
                  name="firstname"
                  className="form_input shadow-none"
                  value={firstname}
                  maxLength={40}
                  onChange={handleInp}
                  style={{
                    background: "#f5f5f5",
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left">Middle name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Middle name"
                  name="midname"
                  className="form_input shadow-none"
                  value={midname}
                  maxLength={40}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (/^[A-Za-z\s]*$/.test(inputText) || inputText === "") {
                      // Only allow alphabetic characters and spaces, or an empty string
                      handleInp(e);
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">
                  Date of Birth(You must be between 18-50 years.)
                </Form.Label>

                {date === "" ? (
                  <Datetime
                    inputProps={{ placeholder: "DATE OF BIRTH" }}
                    required={true}
                    isValidDate={isValidDate}
                    onChange={(date) => {
                      const selectedDate = new Date(date._d);
                      const formattedDate = `${
                        selectedDate.getMonth() + 1
                      }/${selectedDate.getDate()}/${selectedDate.getFullYear()}`;
                      let name = "date";
                      let value = formattedDate;
                      dispatch(handleChange({ name, value }));
                    }}
                    timeFormat={false}
                  />
                ) : (
                  <Form.Control
                    readOnly={true}
                    required
                    type="text"
                    placeholder="DOB"
                    name="date"
                    value={date}
                    className="form_input shadow-none"
                    onChange={handleInp}
                  />
                )}
              </Form.Group>
            </Col>

            <Col md={4} lg={4}>
              <Form.Label className="text-left required">
                Select Gender
              </Form.Label>
              <Form.Select
                disabled
                aria-label="Default select example"
                name="gender"
                value={gender}
                onChange={handleInp}
                className="shadow-none form-select"
              >
                <option value="">Select Gender</option>
                <option value="m">male</option>
                <option value="f">female</option>
              </Form.Select>
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="text-left required">
                Select State Of Origin
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                name="userState"
                onChange={handleStateChange}
                className="shadow-none form-select"
                value={userState}
              >
                <option value="">Select State</option>
                {lg.map((it, i) => {
                  return (
                    <option key={it.id} value={it.id}>
                      {it.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Label className="text-left required">
                Select LGA of Origin
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                name="lga"
                onChange={handleLgaChange}
                className="shadow-none form-select"
                value={lga}
              >
                <option value="">Select LGA</option>
                {lgas.map((it, i) => {
                  return (
                    <option value={it.id} key={i}>
                      {it.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col md={4} lg={4}>
              <Form.Label className="text-left">
                Difficulties In Performing Any Of The Following:
              </Form.Label>

              <Select
                options={difficultyOptions}
                name="challenge"
                value={difficultyOptions.find(
                  (option) => option.value === challenge
                )}
                onChange={handlePhysicalChallenge}
              />
            </Col>
            <Col md={4} lg={4} className="mb-3">
              <Form.Label className="text-left">
                Computer Proficiency
              </Form.Label>

              <Select
                options={computerOptions}
                name="computer"
                value={computerOptions.find(
                  (option) => option.value === computer
                )}
                onChange={handleComputerProficiency}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4} className="mb-3">
              <Form.Label className="text-left required">
                Criminal Case Conviction
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleCriminal}
                name="crimeChoice"
                className="shadow-none form-select"
                value={criminal}
              >
                <option value="">select Conviction</option>
                <option value="yes">yes</option>
                <option value="no">no</option>
              </Form.Select>
            </Col>

            {showDesc && (
              <Col md={4} lg={4} className="mb-3">
                <Form.Label className="text-left required">
                  Describe Crime
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Describe Crime"
                  className="form_input shadow-none"
                  name="crimeDescription"
                  value={crimeDescription || ""}
                  onChange={handleCrimeDescription}
                />
              </Col>
            )}

            <Col md={4} lg={4} className="mb-3">
              <Form.Label className="text-left required">
                Language Spoken well(multiple)
              </Form.Label>
              <Select
                isMulti
                options={options}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                className="shadow-none form-multi"
                closeMenuOnSelect={false}
                onChange={handleMultiiSelect}
                value={
                  language &&
                  language.split(",").map((it) => {
                    return { value: it, label: it };
                  })
                }
              />
            </Col>
            <Col md={4} lg={4} className="mb-3">
              <Form.Label className="text-left">
                Other spoken languages
              </Form.Label>
              <CreatableSelect
                isMulti
                onChange={handleC}
                formatCreateLabel={(inputText) => `Add "${inputText}"`}
                placeholder="Type Other Languages"
                value={
                  otherLang &&
                  otherLang.split(",").map((it) => {
                    return { value: it, label: it };
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">
                  Next of kin name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter next of kin name"
                  name="kinname"
                  className="form_input shadow-none"
                  value={kinname}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (/^[A-Za-z\s]*$/.test(inputText) || inputText === "") {
                      // Only allow alphabetic characters and spaces, or an empty string
                      handleInp(e);
                    }
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left">
                  Next of kin relationship to Applicant
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter next of kin relationship e.g father"
                  name="kinrel"
                  className="form_input shadow-none"
                  value={kinrel}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (/^[A-Za-z\s]*$/.test(inputText) || inputText === "") {
                      // Only allow alphabetic characters and spaces, or an empty string
                      handleInp(e);
                    }
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEText">
                <Form.Label className="text-left required">
                  Next of kin phone
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter next of kin phone"
                  name="kinphone"
                  className="form_input shadow-none"
                  value={kinphone}
                  onChange={handleInp}
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 11))
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {!editBio && (
              <Col md={3} lg={3} className="pt-2 ms-auto">
                <button
                  style={{
                    background: "white",
                    margin: "9px 0px",
                    padding: "10px",
                    fontSize: "13px",
                    border: "1px solid #0e9738",
                    color: "#0e9738",
                  }}
                  className="redz my-btn btn-block mt-4"
                  disabled={loadingSave || loadingSubmit}
                  onClick={handleSave}
                >
                  {loadingSave && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mx-2"
                    />
                  )}
                  SAVE AND CLOSE APPLICATION
                </button>
              </Col>
            )}

            <Col md={3} lg={2} className="pt-2 ms-auto">
              <button
                style={{
                  backgroundColor: "#0e9738",
                  padding: "10px 20px",
                  fontSize: "13px",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
                className="my-btn btn-block mt-4"
                disabled={loadingSubmit || loadingSave}
                onClick={handleSubmit}
              >
                {loadingSubmit && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mx-2"
                  />
                )}
                {editBio ? "UPDATE" : "NEXT"}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  padding: 1.6rem 0;
  text-transform: uppercase;

`;

export default BioData;
