import React from "react";
import styled from "styled-components";

const WhiteButton = ({ onClick, text }) => {
  return (
    <ButtonWhite onClick={onClick}>{text}</ButtonWhite>
  );
};

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
`;

const ButtonWhite = styled(Button)`
  background-color: white;
  border: 1px solid #0e9738;
  color: #0e9738;
`;

export default WhiteButton;
