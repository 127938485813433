import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2";

const Title = ({ title }) => {
  const { accessCode } = useSelector((state) => state.user);
  // eslint-disable-next-line
  const [bttnText, setBttnText] = useState("COPY CODE");
  const copyCode = () => {
    navigator.clipboard
      .writeText(accessCode)
      .then(() => {
        setBttnText("COPIED");
        setTimeout(function () {
          setBttnText("COPY CODE");
        }, 3000);

        //card
        Swal.fire({
          text: "Application ID copied",
          icon: "success",
          confirmButtonColor: "#0b6916",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <Wrapper>
      <p>{title}</p>
      <p className="mx-0">Application-ID: {accessCode}</p>

      <button className="my-btn" style={{ backgroundColor: '#0e9738' }} onClick={copyCode}>
        COPY ID
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #0e9738;
  color: white;
  padding: 0.3rem 0.4rem;
  h4,
  p {
    margin-bottom: 0;
  }
`;
export default Title;
