import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const ProtectedRoute = ({ children }) => {
  const { accessCode } = useSelector((state) => state.user);
//   const accessCode =sessionStorage.getItem("userToken")
// console.log(accessCode)
  if (!accessCode) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;