import React, { useState } from "react";
import { Form, Card, Modal, Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import watermark from "../assets/npc/npc-watermark.png";
import fg from "../assets/npc/fg.png";
import { useNavigate } from "react-router-dom";
import CheckIcon from "../components/CheckIcon.js";
import FixedHeader from "../components/FixedHeader.js";
import Swal from "sweetalert2/dist/sweetalert2";
import { toast } from "react-toastify";
import { handleChange } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import authFetch from "../axios";
import { STEP, SUCCESS_STATUS_DIGIT } from "../utils";
import WhiteButton from "../components/WhiteButton";
import GreenButton from "../components/GreenButton";
import Watermark from "../components/Watermark";

const Instructions = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [agentCode, setAgentCode] = useState("");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleButtonClick = (e, buttonName) => {
    if (e) {
      e.preventDefault();
    }

    if (buttonName === "Continue" && !isChecked) {
      toast.error("Please agree to the instructions", {
        position: "top-center",
        
      });
    }

    if (buttonName === "Continue" && isChecked) {
      setShowModal(true);
    } else if (buttonName === "Back"){
      navigate("/");
    }
  };

  const validateAgentCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    Swal.fire({
      title: "Validating...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton());
      },
    });
    try {
      var jsonData = {
        code: agentCode,
      };

      var config = {
        method: "post",
        url: `/auth/validate-code`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await authFetch(config);
      if (
        data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS &&
        data.palliative.token
      ) {
        setLoading(true);
        sessionStorage.setItem("userToken", data.palliative.token);
        dispatch(
          handleChange({
            name: "accessCode",
            value: data.palliative.token || "",
          })
        );
        try {
          var config1 = {
            method: "get",
            url: `/auth/retrive-data`,
          };
          const { data } = await authFetch(config1);
          console.log(data.palliative.step);

          if (
            data.palliative.step === STEP.NOT &&
            data.palliative.test_passed === false
          ) {
            setLoading(false);
            Swal.close();

            sessionStorage.setItem(
              "preState",
              data.palliative.residence.state_resid_id
            );
            sessionStorage.setItem(
              "preLga",
              data.palliative.residence.lga_resid_id
            );
            sessionStorage.setItem(
              "preName",
              data.palliative.residence.state_resid
            );
            sessionStorage.setItem("access_code", data.palliative.access_code);
            sessionStorage.setItem(
              "preNameLga",
              data.palliative.residence.lga_resid || ""
            );
            dispatch(
              handleChange({
                name: "preState",
                value: data.palliative.residence.state_resid_id,
              })
            );
            dispatch(
              handleChange({
                name: "preLga",
                value: data.palliative.residence.lga_resid_id,
              })
            );
            dispatch(
              handleChange({
                name: "preName",
                value: data.palliative.residence.state_resid,
              })
            );
            dispatch(
              handleChange({
                name: "accessCode",
                value: data.palliative.access_code,
              })
            );
            dispatch(
              handleChange({
                name: "preNameLga",
                value: data.palliative.residence.lga_resid || "",
              })
            );
            Swal.close();
            setShowModal(false);
            Swal.fire({
              title: `ACCESS CODE SUCCESSFULLY VALIDATED`,
              icon: "success",
              text: `Continue with your application`,
              confirmButtonColor: "#0b6916",
              confirmButtonText: "Continue",
            }).then((result) => {
              if (result.value) {
                navigate("/nin");
              }
            });
          } else {
            setLoading(false);
            Swal.close();
            toast.error("Invalid access code", {
              position: "top-left",
            });
            return;
          }
        } catch (error) {
          Swal.close();
          setLoading(false);
          console.log(error);
          toast.error("please retry", {
            position: "top-left",
          });
        }
      } else {
        Swal.close();
        setLoading(false);
        toast.error("Invalid Code access code", {
          position: "top-left",
        });
      }
    } catch (error) {
      Swal.close();
      setLoading(false);
      console.log(error);
      toast.error("Invalid Code", {
        position: "top-left",
      });
    }
  };

  return (
    <Wrapper className="nin-container">
      <FixedHeader logo={fg} title="New Application Instructions" />
      <CardWrapper className="instruction-card">
        <Watermark src={watermark} alt="Background Image" />

        <Card.Body>
          <h5 className="mb-0">Please Note!</h5>

          <p className="">
            <span>
              Carefully read and acknowledge the below instructions to start
              application
            </span>
          </p>

          <ul className="list-unstyled">
            <li className="mb-2">
              <CheckIcon /> Applicant must have a valid National Identification
              Number (NIN).
            </li>
            <li className="mb-2">
              <CheckIcon /> Applicant must write and pass an assessment test.
            </li>
            <li className="mb-2">
              <CheckIcon /> Be informed that you cannot take the assessment test
              more than once.
            </li>
            <li className="mb-2">
              <CheckIcon /> Applicant must use a device with a camera.
            </li>
            <li className="mb-2">
              <CheckIcon /> Applicant's bank details must be the same as the
              details that will be collected from his/her NIN.
            </li>
            <li className="mb-2">
              <CheckIcon /> Applicant must have a valid educational certificate.
            </li>
            <li className="mb-2">
              <CheckIcon /> Applicant must have a valid email account.
            </li>
            <li className="mb-2">
              <CheckIcon /> Applicant must score 60% and above on the assessment
              test.
            </li>
            <li className="mb-2">
              <CheckIcon /> Valid Bank Account Number.
            </li>
            <li className="mb-2">
              <CheckIcon /> Applicant's National Identification Number (NIN) can
              only be used once to access the assessment test.
            </li>
            <li className="mb-2">
              <CheckIcon /> Applicant must have a valid phone number.
            </li>
          </ul>

          <Form>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                required
                type="checkbox"
                onChange={handleCheckChange}
                label="I have carefully read and understood the above instructions"
                className="shadow_none form_check"
              />
            </Form.Group>

            <ButtonContainer>
              <WhiteButton
                onClick={(e) => handleButtonClick(e, "Back")}
                text={"Back"}
              ></WhiteButton>
              <GreenButton
                onClick={(e) => handleButtonClick(e, "Continue")}
                text={"Continue"}
              ></GreenButton>
            </ButtonContainer>
          </Form>
        </Card.Body>

        <Modal
          size="md"
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header className="modal-header" closeButton>
            <Container>
              <Row className="text-center">
                <Col sm={12} md={12}>
                  <Modal.Title
                    id="example-modal-sizes-title-sm"
                    className="mx-auto"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp; Registration Agent Access Code
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <p>
              All Registration agents must validate access code to commence with
              application.
            </p>
            <Form className="" onSubmit={validateAgentCode}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your access code"
                  className="form_input shadow-none"
                  name="app_code"
                  onChange={(e) => setAgentCode(e.target.value)}
                />
              </Form.Group>
              <button type="submit" className="my-btn">
                Validate Access Code
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      </CardWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;
const CardWrapper = styled.div`
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 20px;
`;

export default Instructions;
