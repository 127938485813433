import React, { useState, useEffect, useRef } from "react";
import Title from "../components/Title";
import { Form, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { handleChange, handleStChange } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { components } from "react-select";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Progress from "../components/Progress";
import authFetch from "../axios";
import { EDIT_TYPE, USER_TYPE, SUCCESS_STATUS_DIGIT, STEP } from "../utils";
import FixedHeader from "../components/FixedHeader";
import fg from "../assets/npc/fg.png";
import watermark from "../assets/npc/npc-watermark.png";
import { OpenStreetMap } from "../components/OpenStreetMap";
import BackgroundImage from "../components/BackgroundImage";

const Residence = () => {
  const dispatch = useDispatch();
  const [wards, setWards] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  // eslint-disable-next-line
  const [count, setCount] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [preloading, setPreLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const selectInputRef = useRef();
  const navigate = useNavigate();
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 3;

  const lgaId = sessionStorage.getItem("preLga");
  const stateId = sessionStorage.getItem("preState");
  const lgaName = sessionStorage.getItem("preNameLga");
  const stateName = sessionStorage.getItem("preName");

  //fetch wards based on lgaId
  useEffect(() => {
    const fetchRegistrationCentres = async () => {
      var config = {
        method: "get",
        url: `/resources/ward?lgaId=${lgaId}`,
      };
      const { data } = await authFetch(config);
      setWards(data.palliative);
    };

    if (lgaId) {
      fetchRegistrationCentres();
    }
  }, [lgaId]);

  // Retrieve saved selection from local storage on component mount
  useEffect(() => {
    const dataMine = JSON.parse(localStorage.getItem("dataMine"));
    if (
      dataMine &&
      dataMine.residence &&
      dataMine.residence.wardid &&
      dataMine.residence.ward
    ) {
      const savedWard = {
        id: dataMine.residence.wardid,
        name: dataMine.residence.ward,
      };
      setSelectedWard(savedWard);
      dispatch(handleChange({ name: "ward", value: savedWard.id }));
      dispatch(handleStChange({ name: "wardDet", value: savedWard.name }));
    }
  }, [dispatch]);

  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 1 ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Max limit achieved</div>
        )}
      </components.Menu>
    );
  };

  const bearerToken = sessionStorage.getItem("userToken");
  const { ward, address, mapCenter, editRes, usertype, preState, preLga } =
    useSelector((state) => state.user);

  const handleSave = async () => {
    console.log("residence-mapCenter", mapCenter);

    if (!ward) {
      toast.error("please select registration centre", {
        position: "top-center",
      });
      return;
    }

    if (!address) {
      toast.error("please fill all forms", {
        position: "top-center",
      });
      return;
    }

    setLoadingSave(true);
    try {
      const dat = {
        address: address,
        state: stateId,
        lga: lgaId,
        ward,
        edit: EDIT_TYPE.NEW,
      };

      var config = {
        method: "post",
        url: "/applicant/update-residence-data",
        data: dat,
      };
      const { data } = await authFetch(config);
      console.log(data.palliative);
      if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
        toast.error(data.palliative.message, {
          position: "top-left",
        });
        setLoadingSave(false);
      } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
        toast.success(data.palliative.message, {
          position: "top-left",
        });

        setLoadingSave(false);
        sessionStorage.removeItem("dataMine");
        sessionStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      setLoadingSave(false);
      toast.error("please retry", {
        position: "top-left",
      });
    }
  };

  const handleSubmit = async () => {
    if (!ward) {
      toast.error("please select registration centre", {
        position: "top-center",
      });
      return;
    }
    if (!address) {
      toast.error("please fill all forms", {
        position: "top-center",
      });
      return;
    }
    if (editRes) {
      setLoadingSubmit(true);
      try {
        const dat1 = {
          address: address,
          state: stateId,
          lga: lgaId,
          ward,
          edit: EDIT_TYPE.EDIT,
        };
        var config1 = {
          method: "post",
          url: "/applicant/update-residence-data",

          data: dat1,
        };
        const { data } = await authFetch(config1);

        if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
          toast.error(data.palliative.message, {
            position: "top-left",
          });
          setLoadingSubmit(false);
        } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
          toast.success(data.palliative.message, {
            position: "top-left",
          });

          setLoadingSubmit(false);
          dispatch(handleChange({ name: "editRes", value: false }));
          sessionStorage.setItem("editRes", false);
          navigate("/prev");
        }
      } catch (error) {
        console.log(error);
        setLoadingSubmit(false);
        toast.error("please retry", {
          position: "top-left",
        });
      }
    } else {
      setLoadingSubmit(true);
      try {
        const dat3 = {
          address: address,
          state: stateId,
          lga: lgaId,
          ward,
          edit: EDIT_TYPE.NEW,
        };

        var config = {
          method: "post",
          url: "/applicant/update-residence-data",
          data: dat3,
        };
        const { data } = await authFetch(config);
        console.log(data.palliative);
        if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
          toast.error(data.palliative.message, {
            position: "top-left",
          });
          setLoadingSubmit(false);
        } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
          toast.success(data.palliative.message, {
            position: "top-left",
          });

          setLoadingSubmit(false);
          navigate("/banking-info");
        }
      } catch (error) {
        console.log(error);
        setLoadingSubmit(false);
        toast.error("please retry", {
          position: "top-left",
        });
      }
    }
  };

  const handleMultiSelect = (selectedOption) => {
    if (selectedOption.length === 0) {
      // If no option is selected (i.e., unselected), clear the state and local storage
      setSelectedWard(null);
      dispatch(handleChange({ name: "ward", value: "" }));
      dispatch(handleStChange({ name: "wardDet", value: "" }));

      const dataMine = JSON.parse(localStorage.getItem("dataMine")) || {};
      localStorage.setItem("dataMine", JSON.stringify(dataMine));
    } else {
      const news = selectedOption.map((it) => {
        return it.id;
      });
      const nms = selectedOption.map((it) => {
        return it.name;
      });
      const lName = "wardDet";
      const name = "ward";
      setCount(news);
      const newz = news.join(",");
      const nmz = nms.join(",");

      dispatch(handleChange({ name, value: newz }));
      dispatch(handleStChange({ name: lName, value: nmz }));

      // Save selection to local storage
      const selectedWard = selectedOption[0];
      setSelectedWard(selectedWard);
      const wardId = selectedWard.id;
      const wardName = selectedWard.name;
      const dataMine = JSON.parse(localStorage.getItem("dataMine")) || {};
      dataMine.residence = dataMine.residence || {};
      dataMine.residence.wardid = wardId.toString();
      dataMine.residence.ward = wardName;
      localStorage.setItem("dataMine", JSON.stringify(dataMine));
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      setPreLoading(true);
      try {
        var config = {
          method: "get",
          url: "/auth/retrive-data",
        };
        const { data } = await authFetch(config);
        console.log(data.palliative[0]);
        if (data.palliative.step < STEP.BIODATA) {
          navigate(-1);
        }
        setPreLoading(false);
      } catch (error) {
        console.log(error);
        setPreLoading(false);
      }
    };
    getDetails();
  }, [navigate]);

  useEffect(() => {
    const getDetails = async () => {
      setPreLoading(true);
      try {
        var config = {
          method: "get",
          url: "/auth/retrive-data",
        };
        const { data } = await authFetch(config);
        console.log(data.palliative[0]);
        if (data.palliative.step < STEP.BIODATA) {
          navigate(-1);
        }
        setPreLoading(false);
      } catch (error) {
        console.log(error);
        setPreLoading(false);
      }
    };
    getDetails();
  }, [navigate]);

  useEffect(() => {}, [navigate, bearerToken, preState, preLga]);
  return (
    <Wrapper>
      <FixedHeader logo={fg} title="New Application" />
      {preloading ? (
        <Spinner
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mx-auto"
        />
      ) : (
        <div className="container">
          <div className="pt-5 pb-5"></div>
          <div className="">
            <Progress active={2} />
            <div className="pt-3"></div>
            <Title title="PLACE OF PERMANENT RESIDENCE" />
            <BackgroundImage src={watermark} alt="Background Image" />
            <Row>
              <Col
                md={12}
                lg={12}
                className="p-2"
                style={{
                  background: "#f5f5f5",
                  margin: "9px 0px",
                }}
              >
                <div>
                  {" "}
                  <span
                    className=""
                    style={{
                      color: "red",
                      width: "100%",
                      margin: "opx 8px",
                    }}
                  >
                    NOTE:
                  </span>
                  User who selects registration centres outside their
                  residential address will be fully responsible for their
                  transportation when carrying out their duties and when found
                  out, will result to the termination of employment. Applicants
                  must select registration centres within their residential
                  address.
                </div>
              </Col>
            </Row>

            {usertype === USER_TYPE.ADHOC && (
              <Row>
                <Col md={4} lg={4}>
                  <Form.Label className="text-left">
                    State Of Residence
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={stateName}
                    disabled
                    className="shadow-none form-control"
                  />
                </Col>
                <Col md={4} lg={4}>
                  <Form.Label className="text-left">
                    LGA Of Residence
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={lgaName}
                    disabled
                    className="shadow-none form-control"
                  />
                </Col>

                <Col md={4} lg={4}>
                  <Form.Label className="text-left">
                    Registration Centre
                  </Form.Label>
                  <Select
                    ref={selectInputRef}
                    isMulti
                    options={wards}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    onChange={handleMultiSelect}
                    closeMenuOnSelect={false}
                    isValidNewOption={isValidNewOption}
                    components={{ Menu }}
                    placeholder={"select registration centre"}
                    value={selectedWard ? [selectedWard] : []}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12} lg={12} className="pt-2">
                <Form.Group className="mb-3" controlId="formBasicEText">
                  <Form.Label className="text-left">
                    {" "}
                    Usual Place Of Residence
                  </Form.Label>
                  <div className="map-cont">
                    <OpenStreetMap />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {!editRes && (
                <Col md={3} lg={3} className="pt-2 ms-auto">
                  <button
                    style={{
                      background: "white",
                      margin: "9px 0px",
                      padding: "10px",
                      fontSize: "13px",
                      border: "1px solid #0e9738",
                      color: "#0e9738",
                    }}
                    className="redz my-btn btn-block mt-4"
                    disabled={loadingSave || loadingSubmit}
                    onClick={handleSave}
                  >
                    {loadingSave && (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mx-2"
                      />
                    )}
                    SAVE AND CLOSE APPLICATION
                  </button>
                </Col>
              )}

              <Col md={4} lg={2} className="pt-2">
                <button
                  style={{
                    backgroundColor: "#0e9738",
                    padding: "10px 20px",
                    fontSize: "13px",
                    cursor: "pointer",
                    borderRadius: "5px",
                  }}
                  type="submit"
                  className="my-btn btn-block mt-4"
                  disabled={loadingSubmit || loadingSave}
                  onClick={handleSubmit}
                >
                  {" "}
                  {loadingSubmit && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mx-2"
                    />
                  )}
                  {editRes ? "UPDATE" : "NEXT"}
                </button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 1.6rem 0;
  text-transform: uppercase;
`;

export default Residence;
