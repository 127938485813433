import axios from "axios";
const authFetch = axios.create({
  baseURL: process.env.REACT_APP_API,
});

authFetch.interceptors.request.use(
  function (req) {
    const token = sessionStorage.getItem("userToken");

    if (token) {
      req.headers.authorization = `Bearer ${token}`;
      return req;
    }
    return req;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default authFetch;
