import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import lpg from "../assets/npc/new-bg.png";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { Modal, Form, Container, Col, Row, Spinner } from "react-bootstrap";
import { handleChange } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import authFetch from "../axios";
import { STEP, USER_TYPE, SUCCESS_STATUS_DIGIT } from "../utils";
import Swal from "sweetalert2/dist/sweetalert2";
const Landing = () => {
  const dispatch = useDispatch();
  const [resumeShow, setResumeShow] = useState(false);
  // eslint-disable-next-line
  const [loads, setLod] = useState(false);
  const [appNum, setAppNum] = useState("");
  const [appNum2, setAppNum2] = useState("");

  const [staff, setStaff] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const start = (num) => {
    if (num === USER_TYPE.ADHOC) {
      dispatch(handleChange({ name: "usertype", value: num }));
      sessionStorage.setItem("user-type", num);
      sessionStorage.setItem("checkFirst", true);
      navigate("/instructions");
    }
  };

  const handleResume = async (e) => {
    sessionStorage.clear();
    e.preventDefault();
    setLoading(true);
    try {
      var jsonData = {
        code: appNum,
      };

      var config = {
        method: "post",
        url: `/auth/validate-code`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await authFetch(config);
      console.log(data);
      if (
        data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS &&
        data.palliative.token
      ) {
        setLoading(true);
        sessionStorage.setItem("userToken", data.palliative.token);
        dispatch(
          handleChange({
            name: "accessCode",
            value: data.palliative.token || "",
          })
        );

        try {
          var config1 = {
            method: "get",
            url: `/auth/retrive-data`,
          };

          const { data } = await authFetch(config1);
          console.log(data);

          if (
            data.palliative.step === STEP.TEST &&
            data.palliative.test_passed === false
          ) {
            toast.error(
              "Cannot resume application, you have failed this test.",
              {
                position: "top-left",
              }
            );
            setLoading(false);
            return;
          } else {
            setLoading(false);
            sessionStorage.setItem("nin", data.palliative.nin);
            sessionStorage.setItem("access_code", data.palliative.access_code);
            sessionStorage.setItem("testPassed", true);
            sessionStorage.setItem("user-type", data.palliative.usertype);
            sessionStorage.setItem("fname", data.palliative.bio_data.firstname);
            sessionStorage.setItem("lname", data.palliative.bio_data.lastname);
            sessionStorage.setItem("gender", data.palliative.bio_data.gender);
            sessionStorage.setItem("dob", data.palliative.bio_data.dob);
            sessionStorage.setItem(
              "preState",
              data.palliative.residence.state_resid_id || ""
            );
            sessionStorage.setItem(
              "preLga",
              data.palliative.residence.lga_resid_id || ""
            );
            sessionStorage.setItem(
              "preName",
              data.palliative.residence.state_resid || ""
            );
            sessionStorage.setItem(
              "preNameLga",
              data.palliative.residence.lga_resid || ""
            );
            dispatch(
              handleChange({
                name: "preState",
                value: data.palliative.residence.state_resid_id || "",
              })
            );
            dispatch(
              handleChange({
                name: "preName",
                value: data.palliative.residence.state_resid,
              })
            );
            dispatch(
              handleChange({
                name: "preLga",
                value: data.palliative.residence.lga_resid_id || "",
              })
            );
            dispatch(
              handleChange({
                name: "preNameLga",
                value: data.palliative.residence.lga_resid || "",
              })
            );
            dispatch(
              handleChange({
                name: "usertype",
                value: data.palliative.usertype,
              })
            );
            dispatch(
              handleChange({
                name: "accessCode",
                value: data.palliative.access_code,
              })
            );
            dispatch(
              handleChange({
                name: "surname",
                value: data.palliative.bio_data.lastname || "",
              })
            );
            dispatch(
              handleChange({
                name: "firstname",
                value: data.palliative.bio_data.firstname || "",
              })
            );
            dispatch(
              handleChange({
                name: "gender",
                value: data.palliative.bio_data.gender || "",
              })
            );
            dispatch(
              handleChange({
                name: "date",
                value: data.palliative.bio_data.dob || "",
              })
            );
            dispatch(
              handleChange({ name: "nin", value: data.palliative.nin || "" })
            );

            if (data.palliative.step === STEP.NOT) {
              navigate("/face-capture");
            } else if (data.palliative.step === STEP.FACE) {
              navigate("/contact");
            } else if (data.palliative.step === STEP.CONTACT) {
              navigate("/test");
            } else if (data.palliative.step === STEP.TEST) {
              navigate("/bio-data");
            } else if (data.palliative.step === STEP.BIODATA) {
              navigate("/residence");
            } else if (data.palliative.step === STEP.RESIDENCE) {
              navigate("/banking-info");
            } else if (data.palliative.step === STEP.COMPLETED) {
              toast.error("you have completed this application.", {
                position: "top-left",
              });
              return;
            }
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          toast.error("please retry", {
            position: "top-left",
          });
        }
      } else {
        setLoading(false);
        toast.error("Please retry access code", {
          position: "top-left",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Please retry", {
        position: "top-left",
      });
    }
  };

  const handleResume2 = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      var jsonData = {
        code: appNum2,
      };

      var config = {
        method: "post",
        url: `/auth/validate-code`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await authFetch(config);
      console.log(data);

      if (
        data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS &&
        data.palliative.token
      ) {
        setLoading(true);
        sessionStorage.setItem("userToken", data.palliative.token);
        dispatch(
          handleChange({
            name: "accessCode",
            value: data.palliative.token || "",
          })
        );
        try {
          var config1 = {
            method: "get",
            url: `/auth/retrive-data`,
          };
          const { data } = await authFetch(config1);
          console.log(data.palliative.step);

          if (
            data.palliative.step === STEP.NOT &&
            data.palliative.test_passed === false
          ) {
            setLoading(false);

            sessionStorage.setItem(
              "preState",
              data.palliative.residence.state_resid_id
            );
            sessionStorage.setItem(
              "preLga",
              data.palliative.residence.lga_resid_id
            );
            sessionStorage.setItem(
              "preName",
              data.palliative.residence.state_resid
            );
            sessionStorage.setItem("access_code", data.palliative.access_code);
            sessionStorage.setItem(
              "preNameLga",
              data.palliative.residence.lga_resid || ""
            );
            dispatch(
              handleChange({
                name: "preState",
                value: data.palliative.residence.state_resid_id,
              })
            );
            dispatch(
              handleChange({
                name: "preLga",
                value: data.palliative.residence.lga_resid_id,
              })
            );
            dispatch(
              handleChange({
                name: "preName",
                value: data.palliative.residence.state_resid,
              })
            );
            dispatch(
              handleChange({
                name: "accessCode",
                value: data.palliative.access_code,
              })
            );
            dispatch(
              handleChange({
                name: "preNameLga",
                value: data.palliative.residence.lga_resid || "",
              })
            );
            setStaff(false);
            // setSmShow(true);
            Swal.fire({
              title: `ACCESS CODE SUCCESSFULLY VALIDATED`,
              // text: 'success',
              icon: "success",
              // text: `please save your application ID`,
              text: `Continue with your application`,
              confirmButtonColor: "#0b6916",
              confirmButtonText: "Continue",
            }).then((result) => {
              if (result.value) {
                navigate("/nin");
              }
            });
          } else {
            setLoading(false);
            toast.error("Invalid access code", {
              position: "top-left",
            });
            return;
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          toast.error("please retry", {
            position: "top-left",
          });
        }
      } else {
        setLoading(false);
        toast.error("Invalid Code access code", {
          position: "top-left",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Invalid Code", {
        position: "top-left",
      });
    }
  };

  return (
    <Wrapper>
      {loads && <Loader />}
      <div className="sec-row">
        <div className="left-side">
          <h3 className="" style={{ marginBottom: 0 }}>
            Birth Registration Adhoc
          </h3>
          <h3 className="" style={{ color: "#FFA200", marginTop: 0 }}>
            E - Recruitment
          </h3>
          <p
            className=""
            style={{
              fontSize: "15px",
              border: "1px solid white",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            Note that application is free and approvals are based on merit and
            any citizen posing as an agent collecting money from the applicants
            to fast track or induce approvals will be found and duly prosecuted
            to the full extent of the law.
          </p>
          <div className="dwn mx-0">
            <div className="cta-btns mx-0">
              <button
                className="my-btn  small py-2"
                style={{
                  backgroundColor: "#0E9738",
                  height: "45px",
                }}
                onClick={() => start("1")}
              >
                Start Application
              </button>

              <button
                className="my-btn  small py-2"
                style={{
                  backgroundColor: "#FFA200",
                  height: "45px",
                }}
                onClick={() => setResumeShow(true)}
              >
                Resume Application
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="md"
        show={resumeShow}
        onHide={() => setResumeShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;RESUME APPLICATION
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Form className="" onSubmit={handleResume}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter Your Application Code/NIN"
                className="form_input shadow-none"
                name="app_code"
                onChange={(e) => setAppNum(e.target.value)}
              />
            </Form.Group>
            <button type="submit" className="my-btn">
              RESUME APPLICATION
            </button>
          </Form>

          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={staff}
        onHide={() => {
          setStaff(false);
        }}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp; Registration Agent Access Code
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <p>
            All Registration agents must validate access code to commence with
            application.
          </p>
          <Form className="" onSubmit={handleResume2}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter your access code"
                className="form_input shadow-none"
                name="app_code"
                onChange={(e) => setAppNum2(e.target.value)}
              />
            </Form.Group>
            <button type="submit" className="my-btn">
              Validate Access Code
            </button>
          </Form>

          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  min-height: 100vh;
  width: 100%;
  background-image: url(${lpg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0px 15px;
  .policy {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 1rem;
    a {
      color: white;
      font-weight: bolder;
    }
  }
  .sec-row {
    display: flex;
    /* grid-template-columns: 1fr; */
    /* gap: 0.6rem; */
    /* background-color:red; */
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6)
    );
    padding: 4rem 5rem;
    .left-side {
      color: var(--white);
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      .dwn {
        /* margin-top: 8rem; */
      }
      h1,
      h3 {
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.7rem;
      }

      .cta-btns {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        gap: 0.7rem;
      }
    }
  }

  @media (max-width: 1000px) {
    .sec-row .left-side {
      padding-top: 3.4rem;
    }
    .sec-row .left-side .dwn {
      margin-top: 0rem;
    }
  }

  @media (max-width: 850px) {
    .imgh {
      display: none;
    }
    .sec-row {
      grid-template-columns: 1fr;
      text-align: center;
      padding-top: 1rem;
    }

    .sec-row .left-side .cta-btns {
      justify-content: center;
    }
  }
  @media (max-width: 500px) {
    .sec-row {
      text-align: center;
      padding-top: 1rem;
    }
    .sec-row .left-side .cta-btns {
      flex-direction: column;
      gap: 0.6rem;
    }
  }
`;

export default Landing;
