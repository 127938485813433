import axios from "axios";

export const EDIT_TYPE = {
  EDIT: "1",
  NEW: "0",
};

export const SUCCESS_STATUS = {
  SUCCESS: "1",
  FAILURE: "0",
};

export const SUCCESS_STATUS_DIGIT = {
  SUCCESS: 1,
  FAILURE: 0,
};

export const USER_TYPE = {
  ADHOC: "1",
};

export const STEP = {
  NOT: 0,
  FACE: 1,
  CONTACT: 2,
  TEST: 3,
  RESIDENCE: 5,
  BIODATA: 4,
  BANKING: 6,
  COMPLETED: 6,
};

export const reverseOSM = async (params) => {
  const queryParams = {
    format: "json",
    lon: params.lng,
    lat: params.lat,
  };
  const { data } = await axios.get(
    `https://nominatim.openstreetmap.org/reverse`,
    {
      params: queryParams,
    }
  );
  return data;
};

export const searchOSM = async (params) => {
  const queryParams = {
    format: "json",
    q: params,
  };
  const { data } = await axios.get(
    `https://nominatim.openstreetmap.org/search`,
    {
      params: queryParams,
    }
  );
  return data;
};

export const isValidNigerianPhoneNumber = async(phoneNumber) => {
  const nigerianPhoneRegex = /^(070|080|081|090|091)\d{8}$/;
  return nigerianPhoneRegex.test(phoneNumber);
}
