import React from "react";
import styled from "styled-components";

const Watermark = ({ src, alt }) => {
  return (
    <BackgroundImage src={src} alt={alt}>
    </BackgroundImage>
  );
};

const BackgroundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
`;

export default Watermark;
