import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CheckIcon = () => {
  return (
    <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
  );
};

export default CheckIcon;
