import React from 'react';
import styled from 'styled-components';

const FixedHeader = ({ logo, title }) => {
  return (
    <Header>
      <img src={logo} alt="Logo" className="logo" />
      <h1 className="title">{title}</h1>
    </Header>
  );
};

const Header = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #0E9738;
  padding: 10px 20px; /* Add padding for the top and bottom */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start of the container (left) */
  z-index: 1000;
  border-bottom: 1px solid #ccc;

  .logo {
    height: 60px; 
    background-color: white;
  }

  .title {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
    color: white; /* Set text color to white */
    flex-grow: 1; /* Allow the title to grow and occupy the remaining space */
    text-align: center; /* Center the text within its container */
  }
`;

export default FixedHeader;
