import React from "react";
import styled from "styled-components";

const GreenButton = ({ onClick, text }) => {
  return (
    <ButtonGreen onClick={onClick}>{text}</ButtonGreen>
  );
};

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
`;

const ButtonGreen = styled(Button)`
  background-color: #0e9738;
  border: none;
  color: white;
`;

export default GreenButton;
