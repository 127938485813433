import React from "react";
import styled from "styled-components";

const StyledBackgroundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  z-index: -1;
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const BackgroundImage = ({ src, alt }) => {
  return <StyledBackgroundImage src={src} alt={alt} />;
};

export default BackgroundImage;
