import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import { searchOSM } from '../utils'; 
import { handleCodChange, handleMapChange } from "../redux/userSlice";

export function OpenStreetMap() {
  const defaultLat = 6.5244;
  const defaultLon = 3.3792;
  const [location, setLocation] = useState({ lat: defaultLat, lon: defaultLon });
  // eslint-disable-next-line
  const [locationFetched, setLocationFetched] = useState(false);
  const { address } = useSelector((state) => state.user);

  const [searchQuery, setSearchQuery] =  useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isTrayOpen, setIsTrayOpen] = useState(true); 
  const [mapCenter, setMapCenter] = useState([defaultLat, defaultLon]); // Default center to some initial location
  // eslint-disable-next-line 
  const dispatch = useDispatch();

  const handleAddressChange = (address) => {
    const name = "address";
    const value = address;
    dispatch(handleMapChange({ name, value }));
  };
  const handleCodCng = (cod) => {
    const name = "mapCenter";
    const value = cod;
    dispatch(handleCodChange({ name, value }));
  };

  useEffect(() => {
    setSearchQuery(address);
  }, [address])

  useEffect(() => {
    let isMounted = true; 

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (isMounted) {
            setLocation({ lat: latitude, lon: longitude });
            setLocationFetched(true);
            setMapCenter([latitude, longitude]); // Set the initial map center to the user's location
          }
        },
        (error) => {
          console.error('Error getting user location:', error);
          if (isMounted) {
            setLocationFetched(true); 
          }
        }
      );
    } else {
      if (isMounted) {
        setLocationFetched(true); 
      }
    }

    return () => {
      isMounted = false; 
    };
  }, []);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (searchQuery.trim() !== '') {
        handleSearch();
      } else {
        setSearchResults([]); 
      }
    }, 300);

    return () => clearTimeout(delayedSearch);
    // eslint-disable-next-line 
  }, [searchQuery]);

  const handleSearch = async () => {
    try {
      const results = await searchOSM(searchQuery);
      if (results && results.length > 0) {
        setSearchResults(results);
        setIsTrayOpen(true);  
      } else {
        setSearchResults([]);
        setIsTrayOpen(false);  
      }
    } catch (error) {
      console.error('Error searching address:', error);
      setSearchResults([]);
      setIsTrayOpen(false); 
    }
  };

  const handleLocationSelect = (selectedLocation) => {
    const lat = parseFloat(selectedLocation.lat) || defaultLat;
    const lon = parseFloat(selectedLocation.lon) || defaultLon;
    setLocation({ lat, lon });
    setMapCenter([lat, lon]); // Update map center
    setSearchQuery(selectedLocation.display_name);  
    handleCodCng(selectedLocation);
    setIsTrayOpen(false); 
  };
console.log(address);
  return (
    <div>
      <div>
        <input
          type="text"
          value={address}
          onChange={({target}) => handleAddressChange(target.value)}
          placeholder="Enter address..."
          required={true}
          style={{ width: '100%', padding: '10px', boxSizing: 'border-box', marginBottom: '10px' }}
        />
      </div>
      {isTrayOpen && searchResults.length > 0 && (
        <div style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid transparent', padding: '5px' }}>
          <h5>Search Results:</h5>
          <ul>
            {searchResults.map((result) => (
              <li key={result.place_id}>
                <button
                  onClick={() => handleLocationSelect(result)}
                  style={{ background: 'transparent', color: 'green', border: 'none', cursor: 'pointer' }}
                >
                  {result.display_name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {location.lat !== null && location.lon !== null && (
        <Map height={400} center={mapCenter} defaultZoom={14}> 
          <Marker width={50} anchor={[location.lat, location.lon]} />
          <ZoomControl />
        </Map>
      )}
    </div>
  );
}