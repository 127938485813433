import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import authFetch from "../axios";
import { STEP, SUCCESS_STATUS_DIGIT } from "../utils";

export const WebcamCapture = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState(sessionStorage.getItem("photo"));
  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user",
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const webcamRef = React.useRef(null);

  const handleSubmit = async () => {
    const accessCode = sessionStorage.getItem("access_code") || "";
    if (!image) {
      toast.error("please capture image", {
        position: "top-center",
      });
    } else {
      setLoadingSubmit(true);
      try {
        var ninImage = {
          template: image2,
          format: "JPEG",
        };

        var capturedImage = {
          template: image,
          format: "JPEG",
        };
        const sends = {
          ninImage,
          capturedImage,
          level: "HIGH",
          code: accessCode
        };

        var config = {
          method: "post",
          url: `/face/verify`,
          data: sends,
        };

        const { data } = await authFetch(config);
        console.log(data);
        let score = data.palliative.score;
        if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
          setLoadingSubmit(false);
          Swal.fire({
            title: `Face Successfully Matched with score ${parseInt(score)}`,
            text: ``,
            icon: "success",
            confirmButtonColor: "#0b6916",
            confirmButtonText: `Continue`,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              sessionStorage.setItem("faceMatched", true);
              navigate("/contact");
            }
          });
        } else {
          toast.error(
            `Face verification unsuccessful ${
              parseInt(score) ? `with score of ${parseInt(score)}` : ""
            }`,
            {
              position: "top-center",
            }
          );
          setLoadingSubmit(false);
        }
      } catch (error) {
        setLoadingSubmit(false);
        console.log(error);
        toast.error("please retry", {
          position: "top-center",
        });
      }
    }
  };
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("FileSize: " + imageSrc.length);
    console.log(imageSrc);
    if (imageSrc.length > 5044562) {
      toast.error("image should be less than 5mb.please retake image.", {
        position: "top-center",
      });
      return;
    }
    const realImg = imageSrc.split(",")[1];
    setImage(realImg);
  }, [webcamRef]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        var config = {
          method: "get",
          url: "/auth/retrive-data",
        };
        const { data } = await authFetch(config);
        const usePhoto = sessionStorage.getItem("photo");
        if (data.palliative.step >= STEP.FACE) {
          //sessionStorage.clear();
        } else {
          if (!usePhoto) {
            try {
              var jsonData = {
                nin: data.palliative.nin,
              };
              var config1 = {
                method: "post",
                url: `/nin/verify`,
                data: jsonData,
              };
              const { data: data1 } = await authFetch(config1);
              if (data1.palliative.photo !== "" || null) {
                setImage2(data1.palliative.photo);
              }
            } catch (error) {
              console.log(error);
              toast.error("Unable to verify NIN image", {
                position: "top-center",
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDetails();
  }, []);

  return (
    <div className="pars">
      <h4>Facial Capture</h4>
      <p className="text-center">
        Please ensure to be in a good position and under a good light source
        before capturing.
      </p>
      <div className="webcam-container">
        <div className="webcam-img">
          {image === "" ? (
            <Webcam
              audio={false}
              height={200}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={220}
              videoConstraints={videoConstraints}
            />
          ) : (
            <img src={image} alt="" />
          )}
        </div>
        <div>
          {image !== "" ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                setImage("");
              }}
              className="webcam-btn my-btn btn-block my-1"
            >
              Retake Image
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
              className="webcam-btn my-2 my-btn btn-block my-1"
            >
              Capture
            </button>
          )}
        </div>
      </div>

      <div className="btn-row">
        <button
          className="my-btn mt-4  px-5 d-block btn-block"
          onClick={handleSubmit}
          disabled={loadingSubmit}
        >
          Match face
        </button>
      </div>
    </div>
  );
};
