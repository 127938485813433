
// import axios from "axios";
import { toast } from "react-toastify";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { handleChange } from "../redux/userSlice";
import authFetch from "../axios";
import { SUCCESS_STATUS_DIGIT } from "../utils";
export const saveNin=async(num,type,dqmCode,firstname,surname,middlename,date,gender,dispatch)=>{
    // go return accesscode to be saved in ls
    try {    
      var jsonData ={
        nin:num,
        userType:type,
        accessCode:dqmCode,
        firstName:firstname,
        lastName:surname,
        middleName:middlename,
        dob:date,
        gender
       }

          var config1 = {
            method: "post",
            url: `/auth/update-nin`,
            data:jsonData
          };
          const { data } = await authFetch(config1);
          console.log(data)
          if (data.palliative.status === SUCCESS_STATUS_DIGIT.FAILURE) {
            toast.error(data.palliative.message, {
              position: "top-center",
            });
          } else if (data.palliative.status === SUCCESS_STATUS_DIGIT.SUCCESS) {
            if(data.palliative.access_code!==null){
              sessionStorage.setItem("nin", num);   
              sessionStorage.setItem("access_code", data.palliative.access_code);
              sessionStorage.setItem("userToken", data.palliative.token);
              dispatch(handleChange({name:'accessCode',value:data.palliative.access_code}))
              return true;
  // // call face
  // try {
  //   var formdata1 = new FormData();
  //   formdata1.append("access-code", data.nimc.access_code);
  //   formdata1.append("face", image);
  //   var config = {
  //     method: "post",
  //     url: "https://api.verxid.site/npc/unicef/update-face",
  //     headers: {
  //       Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
  //     },
  //     data: formdata1,
  //   };
  //   const { data:data1 } = await axios(config);
  //   console.log(data1.nimc);
  //   if (data1.nimc.status === 0) {
  //     toast.error(data1.nimc.message, {
  //       position: "top-center",
  //     });
  //   } else if (data1.nimc.status === 1) {
  //     toast.success(data1.nimc.message, {
  //       position: "top-center",
  //     });
  //     // setLoadingSave(false);
  //     // sessionStorage.removeItem("dataMine");
  //   // sessionStorage.clear();
  //   return true;
      
  //   }
  //   } catch (error) {
  //   // setLoadingSave(false);
  //   console.log(error);

  //   toast.error("please retry", {
  //     position: "top-center",
  //   });
  //   return false;
  //   }


             
            } 

            else{
              sessionStorage.setItem("fname", '');
              sessionStorage.setItem("lname", '');
              sessionStorage.setItem("dob", '');
              sessionStorage.setItem("gender", '');
              sessionStorage.setItem("phone", '');
              Swal.fire({
                title: 'This Application ID has been registered.',
                text: 'Complete your application or wait for approval.',
                icon: 'error',
                confirmButtonColor: '#0b6916',
              confirmButtonText: 'Okay'
              })
            }
          }
        } catch (error) {
          console.log(error);
          toast.error("please retry", {
            position: "top-center",
          });
        }
}