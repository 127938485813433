import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import authFetch from "../axios";
import { STEP } from "../utils";
import Swal from "sweetalert2/dist/sweetalert2";
import FixedHeader from "../components/FixedHeader";
import fg from "../assets/npc/fg.png";
import watermark from "../assets/npc/npc-watermark.png";
import BackgroundImage from "../components/BackgroundImage";

const Test = () => {
  const initialMinutes = parseInt(process.env.REACT_APP_TEST_DURATION, 10) || 0;
  const btnnn = useRef(null);
  const btnn = useRef(null);
  const intervalRef = useRef(null);
  const navigate = useNavigate();
  const [questions, setQue] = useState([]);
  const [ans1, setAns1] = useState("");
  const [staff, setStaff] = useState(false);
  const [ans2, setAns2] = useState("");
  const [ans3, setAns3] = useState("");
  const [ans4, setAns4] = useState("");
  const [ans5, setAns5] = useState("");
  const [ans6, setAns6] = useState("");
  const [ans7, setAns7] = useState("");
  const [ans8, setAns8] = useState("");
  const [ans9, setAns9] = useState("");
  const [ans10, setAns10] = useState("");
  const [loading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(true);

  const handleQuestionStart = async (e) => {
    e.preventDefault();

    setStaff(false);
    setLoading(true);
    try {
      const data7 = {
        answers: [
          { question_id: questions[0]?.questionid, answer: ans1 },
          { question_id: questions[1]?.questionid, answer: ans2 },
          { question_id: questions[2]?.questionid, answer: ans3 },
          { question_id: questions[3]?.questionid, answer: ans4 },
          { question_id: questions[4]?.questionid, answer: ans5 },
          { question_id: questions[5]?.questionid, answer: ans6 },
          { question_id: questions[6]?.questionid, answer: ans7 },
          { question_id: questions[7]?.questionid, answer: ans8 },
          { question_id: questions[8]?.questionid, answer: ans9 },
          { question_id: questions[9]?.questionid, answer: ans10 },
        ],
      };

      var config = {
        method: "post",
        url: "/applicant/update-answers",

        data: data7,
      };
      const { data } = await authFetch(config);
      console.log(data);
      setLoading(false);
      if (data.palliative.test_status === true) {
        setIsTimerActive(false);
        Swal.fire({
          title: `Congratulations!!!`,
          icon: "success",
          text: `Test passed with a score of ${data.palliative.test_score}`,
          confirmButtonColor: "#0b6916",
          confirmButtonText: "Start Application",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            navigate("/bio-data");
            sessionStorage.setItem("testPassed", true);
          }
        });
      } else if (data.palliative.test_status === false) {
        toast.error(
          `Test failed with a score of ${data.palliative.test_score}.`,
          {
            position: "top-left",
          }
        );
        sessionStorage.clear();
        navigate("/");
      }
    } catch (error) {
      toast.error("please retry.", {
        position: "top-left",
      });
    }
  };

  const changeAns = (ans, itemNumber) => {
    console.log(ans, itemNumber);
    if (itemNumber === 1) {
      setAns1(ans);
      return;
    }
    if (itemNumber === 2) {
      setAns2(ans);
      return;
    }
    if (itemNumber === 3) {
      setAns3(ans);
      return;
    }
    if (itemNumber === 4) {
      setAns4(ans);
      return;
    }
    if (itemNumber === 5) {
      setAns5(ans);
      return;
    }
    if (itemNumber === 6) {
      setAns6(ans);
      return;
    }
    if (itemNumber === 7) {
      setAns7(ans);
      return;
    }
    if (itemNumber === 8) {
      setAns8(ans);
      return;
    }
    if (itemNumber === 9) {
      setAns9(ans);
      return;
    }
    if (itemNumber === 10) {
      setAns10(ans);
      return;
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      try {
        var config = {
          method: "get",
          url: "/auth/retrive-data",
        };
        const { data } = await authFetch(config);
        if (data.palliative.step >= STEP.TEST) {
          sessionStorage.clear();
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDetails();
  }, []);

  useEffect(() => {
    const fetchState = async () => {
      setLoading(true);
      try {
        var config = {
          method: "get",
          url: "/resources/questions",
        };

        const { data } = await authFetch(config);
        setQue(data.palliative);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("please retry.", {
          position: "top-left",
        });
      }
    };
    fetchState();
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (isTimerActive) {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        } else {
          if (minutes === 0) {
            if (btnnn.current && !isModalOpen) {
              btnnn.current.click();
            }
          } else {
            setSeconds(59);
            setMinutes((prevMinutes) => prevMinutes - 1);
          }
        }
      }
    }, 1000);
  
    return () => clearInterval(intervalRef.current);
  }, [seconds, minutes, isTimerActive, isModalOpen]);
  

  if (loading) {
    return (
      <SpinnerWrap>
        <Spinner />
      </SpinnerWrap>
    );
  }
  return (
    <Wrapper className="nin-container">
      <FixedHeader logo={fg} title="Assessment Test" />
      <div className="container">
        <div className="row">
          <div className="col-10 mx-auto">
            <BackgroundImage src={watermark} alt="Background Image" />

            <div className="pt-5 pb-5"></div>

            <h4 className="mt-5 mb-0 text-left">
              {" "}
              Birth Registration Adhoc Agent’s E-recruitment Assessment Test
            </h4>
            <p className="text-left">
              Correctly answer all the questions below. Each question carries
              10marks
            </p>
            {/* <Timer/> */}
            <p style={{ fontWeight: "bold", fontSize: "27px" }}>
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
            {questions?.map((item, id) => {
              return (
                <div className="mt-3" key={id}>
                  <p className="">
                    {id + 1}. {item.question}
                  </p>
                  <Form className="">
                    {item.options &&
                      Object.values(item.options).map((item, i) => {
                        if (item === "") {
                          return "";
                        }
                        return (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                            key={i}
                          >
                            <Form.Check
                              type="radio"
                              label={item}
                              className="shadow_none form_check"
                              value={`option${i + 1}`}
                              name="ans"
                              onChange={(e) =>
                                changeAns(e.target.value, id + 1)
                              }
                              style={{ marginLeft: "13px" }}
                            />
                          </Form.Group>
                        );
                      })}
                  </Form>
                </div>
              );
            })}
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <button
                type="button"
                className="my-btn "
                style={{ marginLeft: "auto" }}
                onClick={() => setStaff(true)}
              >
                SUBMIT ANSWER
              </button>

              <button
                type="button"
                className="my-btn"
                style={{ opacity: "0" }}
                onClick={handleQuestionStart}
                ref={btnnn}
              >
                SUBMIT ANSWER
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="md"
        show={staff}
        onHide={() => {
          setStaff(false);
          setIsModalOpen(false);
        }}
        onShow={() => setIsModalOpen(true)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton></Modal.Header>
        <Modal.Body>
          <p
            style={{
              textAlign: "center",
            }}
          >
            Are you sure you want to submit this test?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="my-btn"
            onClick={handleQuestionStart}
            ref={btnn}
          >
            SUBMIT ANSWER
          </button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
`;
const SpinnerWrap = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default Test;
