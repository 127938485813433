import React from "react";

const Progress = ({ active }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="hides"
    >
      <p
        className={
          active === 1 ? "progress-active text-uppercase" : "progress-inactive text-uppercase"
        }
      >
        <span
          style={{
            background: "#0e9738",
            width: "17px",
            height: "17px",
            borderRadius: "100%",
            color: "white",
            padding: "8px",
            fontSize: "10px",
            margin: "0px 4px",
          }}
        >
          1
        </span>
        Personal Information
      </p>

      <p
        className={
          active === 2 ? "progress-active text-uppercase" : "progress-inactive text-uppercase"
        }
      >
        <span
          style={{
            background: "#0e9738",
            width: "17px",
            height: "17px",
            borderRadius: "100%",
            color: "white",
            padding: "8px",
            fontSize: "10px",
            margin: "0px 4px",
          }}
        >
          2
        </span>
        Place of Permanent Residence
      </p>

      <p
        className={
          active === 3 ? "progress-active text-uppercase" : "progress-inactive text-uppercase"
        }
      >
        <span
          style={{
            background: "#0e9738",
            width: "17px",
            height: "17px",
            borderRadius: "100%",
            color: "white",
            padding: "8px",
            fontSize: "10px",
            margin: "0px 4px",
          }}
        >
          3
        </span>
        Bank Details
      </p>
    </div>
  );
};

export default Progress;
